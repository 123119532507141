.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}
.card {
  background: url(../../assets/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0%;
  padding: 2%;
  overflow: hidden;
}
.textCard {
  height: 5.5em;
  width: 70%;
  justify-content: center;
  margin-left: 15%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 0;
  border-radius: 50px;
}
.cars {
  width: 100%;
  margin: 2em;
  height: 55vh;
  overflow: scroll;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: ease-out 0.5s;
  background: #eef2f5;
  border-radius: 10px;
  user-select: none;
}
.cars:hover {
  box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.datepicker {
  margin: 1em 0 1em 0;
  width: 100%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: #fdc830; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f37335,
    #fdc830
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f37335,
    #fdc830
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  opacity: 0.8;
  width: 100%;
  height: 100%;
}
.car {
  padding: 1em;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  transition: ease-out 0.5s;
  flex-direction: column;
  margin-right: 1em;
  margin-left: 1em;
  user-select: none;
  margin-top: 1em;
  height: 100%;
  display: flex;
  justify-content: center;
}

.car:hover {
  box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  .rentButton {
    opacity: 1;
  }
}
.infoTag {
  margin-left: 2em;
}

.rentButton {
  position: absolute;
  bottom: 1em;
  right: 2em;
  opacity: 0;
}
.empty {
  display: flex;
  height: 20em;
  width: 20em;
  align-items: center;
  color: white;
  padding: 2em;
  justify-content: center;
  margin-top: 10%;
  margin-left: 35%;
  background-color: rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 50%;
}

.information {
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: ease-out 0.5s;
  background: #eef2f5;
}
.information:hover {
  box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.options {
  margin-top: 1em;
}
.skeleton {
  background: #eef2f5;
}
.carDetails {
  padding: 1.5em;
}
.carImg {
  align-items: flex-start;
  position: absolute;
  right: 0;
  top: 0;
  padding: 2em;
}
