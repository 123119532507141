.menu {
  user-select: none;
}
.userButton {
  vertical-align: "middle";
  width: 100px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  transition: ease-out 0.5s;
  user-select: none;
}
.userButton:hover {
  border: 0px solid;
  box-shadow: 0 0.5px 1.5px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.userButton:focus {
  border: 0px solid;
  box-shadow: none;
}
.userButton:active {
  border: 0px solid;
  box-shadow: none;
}
